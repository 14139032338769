<template>
  <div class="media-custom fd-w-full fd-h-full fd-bg-white">
    <div class="fd-w-full fd-flex fd-items-center fd-justify-start fd-mb-4">
      <span class="fd-text-lg fd-font-bold fd-text-theme-2">{{ $t('media.media') }}</span>
    </div>
    <b-tabs content-class="fd-w-full fd-h-full" pills>
      <b-tab
        class="fd-w-full fd-h-full"
      >
        <template #title>
          <div class="fd-flex fd-items-center">
            <SvgLoader :name="'imageIcon'" />
            <span class="fd-block fd-ml-2">{{ $t('media.photo') }}</span>
          </div>
        </template>

        <Photos :options="phasePhotos" :postAction="uploadApi" @refreshData="getData"/>
      </b-tab>

      <b-tab
        lazy
      >
        <template #title>
          <div class="fd-flex fd-items-center">
            <SvgLoader :name="'videoIcon'" />
            <span class="fd-block fd-ml-2">{{ $t('media.video') }}</span>
          </div>
        </template>
        <Video :options="phaseVideo" :postAction="uploadApi" @refreshData="getData"/>
      </b-tab>

      <b-tab
        lazy
      >
        <template #title>
          <div class="fd-flex fd-items-center">
            <SvgLoader :name="'planIcon'" />
            <span class="fd-block fd-ml-2">{{ $t('media.plan') }}</span>
          </div>
        </template>
        <Plan :options="phasePlan" :postAction="uploadApi" @refreshData="getData"/>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import Photos from "@/views/components/Media/Photos";
import {mapGetters} from "vuex";
import Video from "@/views/components/Media/Video";
import Plan from "@/views/components/Media/Plan";
import BaseService from "@/http/baseService";

export default {
  name: "Media",
  components: {Plan, Video, Photos, BTabs, BTab },
  data() {
    return {
      baseUrl: null
    }
  },
  created() {
    this.baseUrl = BaseService.getInstance().getBaseUrl()
    this.getData()
  },
  computed: {
    ...mapGetters({
      phasePhotos: 'phase/photos',
      phaseVideo: 'phase/video',
      phasePlan: 'phase/plan'
    }),
    uploadApi() {
      return this.baseUrl + `/phases/${this.$route.params.id}/media`
    }
  },
  methods: {
    getData() {
      this.$store.dispatch('phase/getMedia', this.$route.params.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.media-custom {
  height: 100% !important;
  min-height: 100% !important;
  padding: 19px 18px;

  ::v-deep .tabs {
    width: 100%;
    height: 100% !important;
    min-height: 100% !important;
  }

  ::v-deep .tabs {
    .nav-link {
      font-size: 14px;
      color: #B5B8B5;

      svg path {
        stroke: #B5B8B5 !important;
      }

      &.active {
        color: #FFFFFF;

        svg path {
          stroke: #ffffff !important;
        }
      }
    }
  }
}
</style>